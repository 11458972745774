<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';

export interface Props {
  itemsAligned?: 'left' | 'right';
  items?: any[];
  itemsAbsolute?: boolean;
  triggerDisabled?: boolean;
}

withDefaults(defineProps<Props>(), {
  itemsAligned: 'left',
  itemsAbsolute: true,
  triggerDisabled: false,
});

const id = useRandomString();
const target = ref(null);
const closed = ref(true);

const toggle = () => {
  closed.value = !closed.value;
};

const close = () => {
  closed.value = true;
};

onClickOutside(target, close);
</script>

<template>
  <div>
    <slot name="trigger" :toggle="toggle">
      <button type="button" :id="id" class="trigger" @click="toggle">
        <slot name="trigger-label" :toggle="toggle"> Menu </slot>
      </button>
    </slot>
    <transition>
      <div
        v-if="!closed"
        ref="target"
        :class="[{ closed: closed, absolute: itemsAbsolute, 'absolute--left': itemsAligned === 'left', 'absolute--right': itemsAligned === 'right' }]"
      >
        <slot name="items" :items="items" :toggle="toggle">
          <div v-for="item in items" :key="item.key">
            <slot name="item" :item="item">
              {{ item.label }}
            </slot>
          </div>
        </slot>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
@import '~/assets/styles/tw-variables.scss';
@import '~/assets/styles/tw-form.scss';

.trigger:not(:disabled) {
  cursor: pointer;
}

.closed {
  display: none;
}

.absolute {
  margin-top: 0.25rem;
  position: absolute;
  border-radius: 0.25rem;
  background-color: white;
  border: 1px solid $lightGray;
  box-shadow: 0 0 calc(#{$tw-component--gap} * 2) rgba(0, 0, 0, 0.15);

  &--left {
    left: 0;
  }

  &--right {
    right: 4px;
  }

  &:focus-visible {
    outline: none;
  }
}
</style>
